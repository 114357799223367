/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Stack from "@mui/material/Stack";
// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKAvatar from "../../../../components/MKAvatar";
import MKButton from "../../../../components/MKButton";
import MKTypography from "../../../../components/MKTypography";

// Images
import voipPicture1 from "../../../../assets/images/voip0.jpeg";
import voipPicture2 from "../../../../assets/images/voip1.png";
import voipPicture3 from "../../../../assets/images/voip2.png";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            {/* Images */}

            <Grid container justifyContent="center" alignItems="center">
            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="300px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${voipPicture1})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />


            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="300px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${voipPicture2})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />

            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="300px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${voipPicture3})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />
            </Grid>


            <MKTypography variant="h3" my={1} mt={6}>
              VOIP SERVER
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
            
            Our VoIP server solution enables voice communication over the internet, replacing traditional phone lines. It manages the setup, conduction, and teardown of voice calls by handling signaling and media transmission. Key components include:

            <li>SIP Server: Manages the signaling for establishing, managing, and terminating calls. Common examples include OpenSIPS and Kamailio.</li>
            <li>Media Server: Handles the audio (and sometimes video) data during calls. Asterisk is a popular choice.</li>
            <li>Database: Stores user information, call logs, and configuration data.</li>
            <li>Protocols: Utilizes protocols like SIP (Session Initiation Protocol) for signaling and RTP (Real-time Transport Protocol) for media transmission.</li>
            VoIP servers offer features such as call routing, voicemail, conferencing, and call recording, making them indispensable for businesses, call centers, and Internet Telephony Service Providers (ITSPs).
            </MKTypography>

          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">list_alt</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">CHALLENGES</MKTypography>
                  The VoIP server project required specific versions of applications and <br/>packages, many of which were outdated. After ensuring initial functionality <br/>with the specified versions, we upgraded to newer versions to enhance performance and security.
                </MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"z
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">task_alt</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKTypography variant="h6">SOLUTIONS</MKTypography>
                    We conducted extensive simulations using different containers to test <br/>the functionality of packages with newer versions. Through this iterative <br/>testing process, we achieved the desired solution and improved overall <br/>system performance.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">assignment_ind</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKTypography variant="h6">CLIENT</MKTypography>
                  Slingshot.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">warehouse</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">INDUSTRY</MKTypography>
                  Telecommunication.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">terminal</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">TECHNOLOGIES</MKTypography>

                  <li>PHP</li>
                  <li>Asterisk</li>
                  <li>Opensips</li>
                  <li>Mediaproxy</li>
                  <li>Mysql</li>
                  <li>Docker</li>

                </MKTypography>
              </MKBox>

            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
