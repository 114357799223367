import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import App from './App';
import AboutUs from './pages/AboutUs/AboutUs';
import ContactUs from './pages/ContactUs/ContactUs';
import Company from './pages/Company/AboutCompany';
import Projects from './pages/Projects/Projects';
import Services from './pages/Services/Services';
import Scraper from './pages/Projects/sections/projects/Project1';
import Voip from './pages/Projects/sections/projects/Project2';
import Mail from './pages/Projects/sections/projects/Project3';
import DevOps from './pages/Projects/sections/projects/Project4';
import Cloud from './pages/Projects/sections/projects/Project5';
import Journal from './pages/Projects/sections/projects/Project6';

function MainApp() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<App />} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/company" element={<Company />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/services" element={<Services />} />
        <Route path="/web-scraper" element={<Scraper />} />
        <Route path="/voip-server" element={<Voip />} />
        <Route path="/mail-server" element={<Mail />} />
        <Route path="/devops-projects" element={<DevOps />} />
        <Route path="/cloud" element={<Cloud />} />
        <Route path="/journal-app" element={<Journal />} />

      </Routes>
    </Router>
  );
}

export default MainApp;
