/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Stack from "@mui/material/Stack";
// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKAvatar from "../../../../components/MKAvatar";
import MKButton from "../../../../components/MKButton";
import MKTypography from "../../../../components/MKTypography";

// Images
import devopsPicture1 from "../../../../assets/images/devops1.png";
import devopsPicture2 from "../../../../assets/images/devops.png";


function Profile() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            {/* Images */}

            <Grid container justifyContent="center" alignItems="center">
            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="400px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${devopsPicture1})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />


            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="400px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${devopsPicture2})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />


            </Grid>


            <MKTypography variant="h3" my={1} mt={6}>
              DEVOPS PROJECTS
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
            
            Our DevOps projects are dedicated to integrating development and operations processes to enhance the efficiency, reliability, and quality of software delivery. By automating and streamlining the software development lifecycle (SDLC) from code commit to deployment and maintenance, we ensure optimal performance. Key components and practices of our DevOps projects include:

            <li>Continuous Integration (CI): Automates building and testing code changes as they are committed to the repository.</li>
            <li>Continuous Delivery (CD): Automates the deployment of code changes to production and other environments after successful testing.</li>
            <li>Infrastructure as Code (IaC): Manages and provisions infrastructure through machine-readable configuration files.</li>
            <li>Monitoring and Logging: Implements tools and processes to monitor system performance and capture logs for troubleshooting and analysis.</li>
            <li>Collaboration Tools: Utilizes platforms like Slack, Jira, and Confluence to enhance communication and collaboration among development, operations, and other teams.</li>
            </MKTypography>

          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">list_alt</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">CHALLENGES</MKTypography>
                  Technology companies need to implement DevOps practices to achieve better <br/>results in software development and operations.
                </MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"z
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">task_alt</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKTypography variant="h6">SOLUTIONS</MKTypography>
                    We serve as DevOps Engineers to build superior applications in a <br/>controlled environment, utilizing tools to test, debug, and fix them efficiently. <br/>Our approach ensures a seamless integration of development and <br/>operations, leading to continuous improvement and innovation.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">warehouse</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">INDUSTRY</MKTypography>
                  Technology.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">terminal</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">TECHNOLOGIES</MKTypography>

                  <li>Ansible</li>
                  <li>Jenkins</li>
                  <li>Github Actions</li>
                  <li>Asana</li>
                  <li>Docker</li>
                  <li>Kubernetes</li>
                  <li>Snyk</li>
                  <li>Slack</li>
                  <li>Terraform</li>
                  <li>Lucidchat</li>
                  <li>Azure</li>
                  <li>AWS</li>
                  <li>Google Cloud</li>
                  <li>And more...</li>


                </MKTypography>
              </MKBox>

            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
