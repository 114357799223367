// @mui material components
import Icon from "@mui/material/Icon";
// @mui icons
import GitHubIcon from "@mui/icons-material/GitHub";

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// import React from 'react';
// import { Link } from 'react-router-dom';
// import routes from './routes';

const routes = [

  {
    name: "About Us",
    component: RouterLink,
    to: "/about-us",
    // icon: <Icon>article</Icon>,
    // route: "/pages/landing-pages/about-us",
    // route: <AboutUs />,
    route: "/about-us",
  },

  {
    name: "Projects",
    component: RouterLink,
    to: "/projects",
    // icon: <Icon>view_day</Icon>,
    route: "/projects",
  },

  {
    name: "Services",
    component: RouterLink,
    to: "/services",
    // icon: <Icon>view_day</Icon>,
    route: "/services",
  },

  {
    name: "Contact Us",
    component: RouterLink,
    to: "/contact-us",
    // icon: <Icon>dashboard</Icon>,
    route: "/contact-us",
  },

  {
    name: "github",
    icon: <GitHubIcon />,
    href: "https://github.com/dennys9415",
  },

];

export default routes;
