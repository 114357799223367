// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import HorizontalTeamCard from "../../../examples/Cards/TeamCards/HorizontalTeamCard";

import { Link as RouterLink } from 'react-router-dom';
import CompanyInfo from "../../Company/sections/Singularity.js";
import CenteredBlogCard from "../../../examples/Cards/BlogCards/CenteredBlogCard";


// Images
// import team1 from "assets/images/team-5.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Our Projects
            </MKTypography>
            {/* <MKTypography variant="body2" color="white" opacity={0.8}>
              Technology projects, telecommunication, etc....
            </MKTypography> */}
          </Grid>
        </Grid>
        <Grid container spacing={3}>

          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                // image={team1}
                image="https://hasdata.com/assets/cache_image/components/images/blog/web-scraping.14c36f6b.jpg"
                name="Web Scraping"
                position={{ color: "info", label: "Programming" }}
                description="Getting information from all kind of websites and save it in anyway possible."
              />
            </MKBox>
          </Grid> */}


          <Grid item xs={12} lg={6} mt={2}>
            <CenteredBlogCard
              image="https://hasdata.com/assets/cache_image/components/images/blog/web-scraping.14c36f6b.jpg"
              title="Web Scraper"
              description="Getting information from all kind of websites and save it in anyway possible."
              action={{
                type: "internal",
                route: "/web-scraper",
                to: "/web-scraper",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>


          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnSuys8qNp7DZGNn8pwJtevQviXWeNJtyyt2LdKMgPvnVL31XRUK0fb_mWloKPZElZ144&usqp=CAU"
                name="Voip Server"
                position={{ color: "info", label: "Networking" }}
                description="Server developed in Asterisk, Opensips and mediaproxy."
              />
            </MKBox>
          </Grid> */}


          <Grid item xs={12} lg={6} mt={2}>
            <CenteredBlogCard
              image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSnSuys8qNp7DZGNn8pwJtevQviXWeNJtyyt2LdKMgPvnVL31XRUK0fb_mWloKPZElZ144&usqp=CAU"
              title="Voip Server"
              description="Server developed in Asterisk, Opensips and mediaproxy."
              action={{
                type: "internal",
                route: "/voip-server",
                to: "/voip-server",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>

          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE-k6Rm00oRhyWl8ZBYTqSmjdn-tIROuPMlQ&s"
                name="Mail Server"
                position={{ color: "info", label: "Networking" }}
                description="Developing of Private Mail Server for Big companies."
              />
            </MKBox>
          </Grid> */}

          <Grid item xs={12} lg={6} mt={2}>
            <CenteredBlogCard
              image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE-k6Rm00oRhyWl8ZBYTqSmjdn-tIROuPMlQ&s"
              title="Mail SMTP Server"
              description="Developing of Private Mail Server for Big companies."
              action={{
                type: "internal",
                route: "/mail-server",
                to: "/mail-server",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>


          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image="https://bigstep.com/assets/images/blog/server-guide.jpg"
                name="Devops Projects"
                position={{ color: "info", label: "DevOps" }}
                description="CI/CD, Management, developing and debugging Applications, Databases on Servers and containers."
              />
            </MKBox>
          </Grid> */}


          <Grid item xs={12} lg={6} mt={2}>
            <CenteredBlogCard
              image="https://bigstep.com/assets/images/blog/server-guide.jpg"
              title="Devops Projects"
              description="CI/CD, Management, developing and debugging Applications, Databases on Servers and containers."
              action={{
                type: "internal",
                route: "/devops-projects",
                to: "/devops-projects",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>


          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image="https://www.hkcert.org/f/guideline/218189/930p523/hkcert-Cloud%20Storage%20Security%20banner-1860x1046.jpg"
                name="Cloud Storage"
                position={{ color: "info", label: "Programming" }}
                description="Deployment of server, development of APIs in Nodejs."
              />
            </MKBox>
          </Grid> */}

          <Grid item xs={12} lg={6} mt={2}>
            <CenteredBlogCard
              image="https://www.hkcert.org/f/guideline/218189/930p523/hkcert-Cloud%20Storage%20Security%20banner-1860x1046.jpg"
              title="Cloud Storage App"
              description="Deployment of server, development of APIs in Nodejs."
              action={{
                type: "internal",
                route: "/cloud",
                to: "/cloud",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>

          {/* <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image="https://static1.makeuseofimages.com/wordpress/wp-content/uploads/2018/09/bullet-journal-apps.jpg"
                name="Journal App"
                position={{ color: "info", label: "Programming" }}
                description="Server deployment, setting up of web server, Development of Apis in dPHP."
              />
            </MKBox>
          </Grid> */}

          <Grid item xs={12} lg={6} mt={2}>
            <CenteredBlogCard
              image="https://static1.makeuseofimages.com/wordpress/wp-content/uploads/2018/09/bullet-journal-apps.jpg"
              title="Journal Bullet App"
              description="Server deployment, setting up of web server, Development of Apis in dPHP."
              action={{
                type: "internal",
                route: "/journal-app",
                to: "/journal-app",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid>

        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
