/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Stack from "@mui/material/Stack";
// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKAvatar from "../../../../components/MKAvatar";
import MKButton from "../../../../components/MKButton";
import MKTypography from "../../../../components/MKTypography";

// Images
import cloudPicture1 from "../../../../assets/images/cloud.png";
import cloudPicture2 from "../../../../assets/images/cloud1.png";
import cloudPicture3 from "../../../../assets/images/cloud2.png";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            {/* Images */}

            <Grid container justifyContent="center" alignItems="center">

            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="300px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${cloudPicture1})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />

            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="300px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${cloudPicture3})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />

            </Grid>


            <MKTypography variant="h3" my={1} mt={6}>
              CLOUD STORAGE APP
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
            
            Our cloud storage application is designed to provide a centralized solution for managing and accessing digital content by storing media or files on a server. It supports various deployment models, including cloud, private, and hybrid platforms.

            </MKTypography>

          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">list_alt</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">CHALLENGES</MKTypography>
                  The development of a storage application that can be deployed on <br/>multiple cloud platforms, with a specific focus on Storj, required creating <br/>robust APIs and building a scalable infrastructure.
                </MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"z
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">task_alt</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKTypography variant="h6">SOLUTIONS</MKTypography>
                    We deployed the necessary infrastructure and developed APIs to ensure <br/>the application functions as intended. This involved extensive testing and <br/>optimization to achieve seamless performance and reliability.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">assignment_ind</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKTypography variant="h6">CLIENT</MKTypography>
                  Crypto Repair.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">warehouse</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">INDUSTRY</MKTypography>
                  Technology.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">terminal</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">TECHNOLOGIES</MKTypography>

                  <li>NodeJS</li>
                  <li>PHP</li>
                  <li>Python</li>
                  <li>AWS</li>
                  <li>Storj</li>


                </MKTypography>
              </MKBox>

            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
