import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";


// Material Kit 2 React components
// import MKBox from "components/MKBox";
import MKBox from "./MKBox";

// import MKButton from "components/MKButton";
import MKButton from "./MKButton";
// import MKTypography from "components/MKTypography";
import MKTypography from "./MKTypography";

// Images
// import bgImage from "assets/images/bg-coworking.jpeg";
import bgImage from "../assets/images/fractal.jpg";

// Material Kit 2 React components
// import MKSocialButton from "./MKSocialButton";
// import Icon from "@mui/material/Icon";
// import AboutUs from "../pages/LandingPages/AboutUs";





function HeaderOne() {
  return (
    <MKBox component="header" position="relative">


      {/* <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
        <Container>
          <Grid container flexDirection="row" alignItems="center">
            <MKTypography
              component={Link}
              href="#"
              variant="button"
              color="white"
              fontWeight="regular"
              py={0.8125}
              mr={2}
            >
              Singularity Box
            </MKTypography>
            <MKButton
              variant="outlined"
              color="white"
              sx={{ display: { xs: "block", lg: "none" }, ml: "auto" }}
            >
              <MKBox component="i" color="white" className="fas fa-bars" />
            </MKButton>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              my={0}
              mx="auto"
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  component={RouterLink}
                  to="/about-us"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                >
                  About Us
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  // component={Link}
                  component={RouterLink}
                  // href="#"
                  to="/contact-us"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  // onClick={(e) => e.preventDefault()}
                >
                  Contact Us
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="#"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  Projects
                </MKTypography>
              </MKBox>
            </MKBox>
            <MKBox
              component="ul"
              display={{ xs: "none", lg: "flex" }}
              p={0}
              m={0}
              sx={{ listStyle: "none" }}
            >
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <MKBox component="i" color="white" className="fab fa-twitter" />
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <MKBox component="i" color="white" className="fab fa-facebook" />
                </MKTypography>
              </MKBox>
              <MKBox component="li">
                <MKTypography
                  component={Link}
                  href="#"
                  variant="button"
                  p={1}
                  onClick={(e) => e.preventDefault()}
                >
                  <MKBox component="i" color="white" className="fab fa-instagram" />
                </MKTypography>
              </MKBox>
            </MKBox>
          </Grid>
        </Container>
      </MKBox> */}




      <MKBox
        display="flex"
        alignItems="center"
        minHeight="100vh"
        sx={{
          backgroundImage: ({ palette: { gradients }, functions: { linearGradient, rgba } }) => `${linearGradient(rgba(gradients.dark.main, 0.5), rgba(gradients.dark.state, 0.5))}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Container >
          {/* <Grid container item xs={12} md={7} lg={6} flexDirection="column" justifyContent="center" > */}
          <Grid container item xs={12} lg={7} justifyContent="center" mx="auto">
            <MKTypography
              variant="h1"
              color="white"
              mb={3}
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              Singularity Box LLC
            </MKTypography>
            <MKTypography variant="body1" color="white" textAlign="center" opacity={0.8} px={{ xs: 6, lg: 12 }} mt={1}>
              {/* We develope anything to everyone.  */}
              We transform ideas into projects
            </MKTypography>
            <MKTypography variant="body1" color="white" textAlign="center" opacity={0.8} px={{ xs: 6, lg: 12 }} mt={1}>
              We create digital products that innovate industry standards
            </MKTypography>
            {/* <Stack direction="row" spacing={1} mt={3}>
              <MKButton color="white">Get Started</MKButton>
              <MKButton variant="text" color="white">
                Read more
              </MKButton>
            </Stack> */}
          </Grid>
        </Container>
      </MKBox>
    </MKBox>
  );
}

export default HeaderOne;