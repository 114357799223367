/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Stack from "@mui/material/Stack";
// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKAvatar from "../../../../components/MKAvatar";
import MKButton from "../../../../components/MKButton";
import MKTypography from "../../../../components/MKTypography";

// Images
import emailPicture1 from "../../../../assets/images/email.png";
import emailPicture2 from "../../../../assets/images/email1.png";
import emailPicture3 from "../../../../assets/images/email2.png";
import emailPicture4 from "../../../../assets/images/email3.png";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            {/* Images */}

            <Grid container justifyContent="center" alignItems="center">
            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="300px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${emailPicture1})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />


            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="300px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${emailPicture2})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />

            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="300px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${emailPicture3})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />

            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="300px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${emailPicture4})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />
            </Grid>


            <MKTypography variant="h3" my={1} mt={6}>
              EMAIL SMTP SERVER
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
            
            Our Linux-based email server is designed to efficiently manage and facilitate the sending, receiving, and storing of email messages. Key functionalities include:
            <li>Email Routing: Ensures emails are delivered to the correct destinations.</li>
            <li>Mailbox Management: Stores and organizes email messages for users.</li>
            <li>Security: Implements robust authentication, encryption (TLS/SSL), and filtering to protect against spam and malware.</li>
            <li>Accessibility: Provides access to emails via IMAP/POP3 and webmail interfaces.</li>
            Linux email servers are highly customizable, scalable, and reliable, making them suitable for businesses, educational institutions, and personal use.

            </MKTypography>

          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">list_alt</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">CHALLENGES</MKTypography>
                  The client required a secure, self-contained Linux email server to manage <br/>all email communications internally.
                </MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"z
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">task_alt</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKTypography variant="h6">SOLUTIONS</MKTypography>
                    We installed and configured the necessary packages and applications to <br/>establish the SMTP server. Additionally, we implemented a graphical interface <br/>to manage domains, users, and their email accounts, ensuring ease of use <br/>and efficient management.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">assignment_ind</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKTypography variant="h6">CLIENT</MKTypography>
                  Giocomms.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">warehouse</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">INDUSTRY</MKTypography>
                  Healthcare.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">terminal</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">TECHNOLOGIES</MKTypography>

                  <li>Linux</li>
                  <li>Postfix</li>
                  <li>Roundcube</li>
                  <li>Dovecot</li>
                  <li>Linode Storage Object</li>


                </MKTypography>
              </MKBox>

            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
