/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import HorizontalTeamCard from "../../../examples/Cards/TeamCards/HorizontalTeamCard";

// Images
import slingshot from "../../../assets/images/slingshot2.png";
// import team2 from "assets/images/bruce-mars.jpg";
// import team3 from "assets/images/ivana-squares.jpg";
// import team4 from "assets/images/ivana-square.jpg";


function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Our Clients
            </MKTypography>
            <MKTypography variant="body2" color="white" opacity={0.8}>
            We have had the privilege of working with a diverse range of clients, from small startups to large enterprises. Our solutions have helped businesses streamline their operations, enhance security, and achieve their technological goals.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                // image={team1}
                image="https://media.licdn.com/dms/image/C4D1BAQGV20LQ3ZwWyg/company-background_10000/0/1640888453149/cryptorepair_cover?e=2147483647&v=beta&t=4YKPXhRfRWjE73sv26oC6k0zK8NWYJe8HtUmyn0clQA"
                name="Crypto Repair"
                position={{ color: "info", label: "Company" }}
                description="DevOps Management Company."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image="https://lcubestudios.io/img/banners/default_white_opengraph.png"
                name="Lcube Studios"
                position={{ color: "info", label: "Company" }}
                description="Development Company."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image="https://mms.businesswire.com/media/20231004918567/en/1906970/23/Kaplan-Registed%C2%AE_Logo-Purple_on_White-RGB.jpg"
                name="Kaplan"
                position={{ color: "info", label: "Company" }}
                description="Medicine platform."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image={slingshot}
                // image="https://slingshot.tel/wp-content/uploads/2014/08/Slingshot-VoIP-Business-Phone-Logo-e1436992287864.png"
                name="Slingshot"
                position={{ color: "info", label: "Company" }}
                description="Communication Company."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
