// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
// import MKTypography from "components/MKTypography";
import MKBox from "../../../components/MKBox";
import MKTypography from "../../../components/MKTypography";

// Material Kit 2 React examples
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";
import HorizontalTeamCard from "../../../examples/Cards/TeamCards/HorizontalTeamCard";

// Images
// import team1 from "assets/images/team-5.jpg";

function Team() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} md={8} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Our Services
            </MKTypography>
            {/* <MKTypography variant="body2" color="white" opacity={0.8}>
              Developing, Management, Debugging.
            </MKTypography> */}
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                // image={team1}
                image="https://imageio.forbes.com/specials-images/imageserve/642445004586b4330e81b689//960x0.jpg?height=406&width=711&fit=bounds"
                name="Cybersecurity"
                position={{ color: "info", label: "Security" }}
                description="Protect your business from cyber threats with our comprehensive cybersecurity solutions. We offer risk assessments, threat detection, and mitigation strategies to safeguard your digital assets."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <HorizontalTeamCard
                image="https://ewm.swiss/application/files/5716/0379/3279/social_media_.jpg"
                name="Network Management"
                position={{ color: "info", label: "Networking" }}
                description="Optimize your network infrastructure with our expert network management services. We provide network design, implementation, monitoring, and maintenance to ensure smooth and efficient operations."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image="https://www.kellerschroeder.com/wp-content/uploads/2023/08/Cloud-Solutions-2-Evansville-Nashville.png"
                name="Cloud Solutions"
                position={{ color: "info", label: "Development" }}
                description="Leverage the power of the cloud with our tailored cloud solutions. We offer cloud migration, management, and optimization services to help you harness the full potential of cloud technology."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQp7fJWCsHehELVDodP1RaKU-j2H2jzmcQq2Q&s"
                name="App Development"
                position={{ color: "info", label: "Development" }}
                description="Transform your ideas into reality with our custom application development services. We specialize in developing robust and scalable applications tailored to your business needs."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }} mt={5}>
              <HorizontalTeamCard
                image="https://magepow.com/blog/wp-content/uploads/2021/11/ung-dung-web-api.jpg"
                name="Apis Development"
                position={{ color: "info", label: "Programming" }}
                description="Enhance your software capabilities with our API development services. We create secure and efficient APIs to integrate your systems and streamline your operations."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
              <HorizontalTeamCard
                image="https://marvel-b1-cdn.bc0a.com/f00000000236551/dt-cdn.net/wp-content/uploads/2021/07/13429_ILL_DevOpsLoop.png"
                name="DevOps Solutions"
                position={{ color: "info", label: "DevOps" }}
                description="Accelerate your software development lifecycle with our DevOps solutions. We implement CI/CD pipelines, automate infrastructure management, and enhance collaboration between development and operations teams."
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Team;
