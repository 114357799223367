// src/pages/AboutUs/AboutUs.js
import React from "react";
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import { ThemeProvider } from '@mui/material/styles';
import theme from '../../assets/theme';
import CssBaseline from "@mui/material/CssBaseline";


import MKBox from "../../components/MKBox";
import MKTypography from "../../components/MKTypography";

import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";


import routes from "../../routes_new";

import bgImage from "../../assets/images/fractal.jpg";

import Projects from "./sections/Information";

import DefaultFooter from "../../examples/Footers/DefaultFooter";
import footerRoutes from "../../footer.routes";



function AboutUs() {
  return (
    <>
    {/* <Container> */}
        <ThemeProvider theme={theme}>
        <CssBaseline />
            <DefaultNavbar
                routes={routes}
                // action={{
                // type: "external",
                // route: "https://www.creative-tim.com/product/material-kit-react",
                // label: "free download",
                // color: "default",
                // }}
                // transparent
                // light
            />
        
            <MKBox
            minHeight="75vh"
            width="100%"
            sx={{
                backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
                `${linearGradient(
                    rgba(gradients.dark.main, 0.6),
                    rgba(gradients.dark.state, 0.6)
                )}, url(${bgImage})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                display: "grid",
                placeItems: "center",
            }}
            >
            <Container>
                <Grid
                container
                item
                xs={12}
                lg={8}
                justifyContent="center"
                alignItems="center"
                flexDirection="column"
                sx={{ mx: "auto", textAlign: "center" }}
                >
                <MKTypography
                    variant="h1"
                    color="white"
                    sx={({ breakpoints, typography: { size } }) => ({
                    [breakpoints.down("md")]: {
                        fontSize: size["3xl"],
                    },
                    })}
                >
                    Singularity Box Projects
                </MKTypography>
                <MKTypography variant="body1" color="white" opacity={0.8} mt={1} mb={3}>
                    We create scalable, efficient, secure solutions.
                </MKTypography>

                <MKTypography variant="h6" color="white" mt={8} mb={1}>
                    Find us on
                </MKTypography>
                <MKBox display="flex" justifyContent="center" alignItems="center">
                    <MKTypography component="a" variant="body1" color="white" href="https://github.com/dennys9415" mr={3}>
                    <i className="fab fa-github" />
                    </MKTypography>
                    <MKTypography component="a" variant="body1" color="white" href="https://www.instagram.com/singularity_box/" mr={3}>
                    <i className="fab fa-instagram" />
                    </MKTypography>
                    {/* <MKTypography component="a" variant="body1" color="white" href="#" mr={3}>
                    <i className="fab fa-twitter" />
                    </MKTypography>
                    <MKTypography component="a" variant="body1" color="white" href="#">
                    <i className="fab fa-google-plus" />
                    </MKTypography> */}
                </MKBox>
                </Grid>
            </Container>
            
            </MKBox>
            <Card
            sx={{
                p: 5,
                mx: { xs: 2, lg: 3 },
                mt: -8,
                mb: 4,
                boxShadow: ({ boxShadows: { xxl } }) => xxl,
            }}
            >
            <Projects />
            </Card>
            <MKBox pt={6} px={1} mt={6}>
                <DefaultFooter content={footerRoutes} />
            </MKBox>
            </ThemeProvider>
        </>
  );
}

export default AboutUs;