/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Tooltip from "@mui/material/Tooltip";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";
import MKBox from "../../../components/MKBox";
import MKButton from "../../../components/MKButton";
import MKTypography from "../../../components/MKTypography";

// Images
// import bgImage from "assets/images/shapes/waves-white.svg";
import bgImage from "../../../assets/images/shapes/waves-white.svg";

function Download() {
  return (
    <MKBox component="section" py={{ xs: 0, sm: 12 }}>
      {/* <MKBox
        variant="gradient"
        bgColor="dark"
        position="relative"
        borderRadius="xl"
        sx={{ overflow: "hidden" }}
      >
        <MKBox
          component="img"
          src={bgImage}
          alt="pattern-lines"
          position="absolute"
          top={0}
          left={0}
          width="100%"
          zIndex={1}
          opacity={0.2}
        />
        <Container sx={{ position: "relative", zIndex: 2, py: 12 }}>
          <Grid container item xs={12} md={7} justifyContent="center" mx="auto" textAlign="center">
            <MKTypography variant="h3" color="white">
              Do you love this awesome
            </MKTypography>
            <MKTypography variant="h3" color="white" mb={1}>
              UI Kit for ReactJS &amp; MUI?
            </MKTypography>
            <MKTypography variant="body2" color="white" mb={6}>
              Cause if you do, it can be yours for FREE. Hit the button below to navigate to
              Creative Tim where you can find the Design System in HTML. Start a new project or give
              an old Bootstrap project a new look!
            </MKTypography>
            <MKButton
              variant="gradient"
              color="info"
              size="large"
              component="a"
              href="https://www.creative-tim.com/product/material-kit-react"
              sx={{ mb: 2 }}
            >
              Download Now
            </MKButton>
          </Grid>
        </Container>
      </MKBox> */}
      <Container>
        <Grid container item xs={6} mx="auto">
          <MKBox textAlign="center">
            <MKTypography variant="h3" mt={6} mb={3}>
              We work with different technologies
            </MKTypography>
            <Grid container spacing={3} justifyContent="center">
              <Grid item xs={4} lg={2}>
                <Tooltip title="Python">
                  <MKBox
                    component="a"
                    href="https://www.python.org/t"
                    target="_blank"
                  >
                    <MKBox
                      component="img"
                      src="https://logos-world.net/wp-content/uploads/2021/10/Python-Symbol.png"
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>
              <Grid item xs={4} lg={2}>
                <Tooltip title="PHP">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    // href="#"
                    href="https://www.php.net/"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <MKBox
                      component="img"
                      src="https://miro.medium.com/v2/resize:fit:1400/1*Y1hq9sHXG26Fyhys81z8rg.png"
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>
              <Grid item xs={4} lg={2}>
                <Tooltip title="NODE JS">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="https://nodejs.org/en"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <MKBox
                      component="img"
                      src="https://miro.medium.com/v2/resize:fit:900/1*TY9uBBO9leUbRtlXmQBiug.png"
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={4} lg={2}>
                <Tooltip title="REACTJS">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="https://react.dev/"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <MKBox
                      component="img"
                      src="https://bilginc.com/editorFiles/3147aa77.png"
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={4} lg={2}>
                <Tooltip title="Amazon Web Service">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="https://aws.amazon.com/free/?trk=fce796e8-4ceb-48e0-9767-89f7873fac3d&sc_channel=ps&ef_id=CjwKCAjwm_SzBhAsEiwAXE2Cv8-fo2d9v7nCID5qxYALZlMs4bivJHpVCmybZuTlFsQKbd0nb81VgRoCECQQAvD_BwE:G:s&s_kwcid=AL!4422!3!592542020599!e!!g!!aws!1644045032!68366401852&all-free-tier.sort-by=item.additionalFields.SortRank&all-free-tier.sort-order=asc&awsf.Free%20Tier%20Types=*all&awsf.Free%20Tier%20Categories=*all"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <MKBox
                      component="img"
                      src="https://partner.zoom.us/wp-content/uploads/2022/12/2022_Zoom-AWS_Lockup_RGB-1-e1672857797889-1024x760.png"
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>
              <Grid item xs={4} lg={2}>
                <Tooltip title="Azure">
                  <MKBox
                    component="a"
                    href="https://azure.microsoft.com/en-us"
                    target="_blank"
                  >
                    <MKBox
                      component="img"
                      src="https://miro.medium.com/v2/resize:fit:1400/0*MCsGhkvp8vwPqKFH.png"
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>
              <Grid item xs={4} lg={2}>
                <Tooltip title="GOOGLE CLOUD">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="https://cloud.google.com/"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <MKBox
                      component="img"
                      src="https://www.netscout.com/sites/default/files/2022-02/17/images/cloud-lockup-logo-500px.png"
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={4} lg={2}>
                <Tooltip title="CISCO">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="https://www.cisco.com/"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <MKBox
                      component="img"
                      src="https://upload.wikimedia.org/wikipedia/commons/thumb/0/08/Cisco_logo_blue_2016.svg/640px-Cisco_logo_blue_2016.svg.png"
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>

              <Grid item xs={4} lg={2}>
                <Tooltip title="FORTINET">
                  <MKBox
                    // opacity={0.5}
                    component="a"
                    href="https://www.fortinet.com/"
                    target="_blank"
                    // onClick={(e) => e.preventDefault()}
                  >
                    <MKBox
                      component="img"
                      src="https://www.ncnonline.net/wp-content/uploads/2020/09/Fortinet-logo.png"
                      width="100%"
                    />
                  </MKBox>
                </Tooltip>
              </Grid>





            </Grid>
          </MKBox>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Download;
