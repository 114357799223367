import React, { useState } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import emailjs from '@emailjs/browser';
import theme from '../../assets/theme';
import CssBaseline from "@mui/material/CssBaseline";
import Grid from "@mui/material/Grid";
import MKBox from "../../components/MKBox";
import MKInput from "../../components/MKInput";
import MKButton from "../../components/MKButton";
import MKTypography from "../../components/MKTypography";
import DefaultNavbar from "../../examples/Navbars/DefaultNavbar";
import DefaultFooter from "../../examples/Footers/DefaultFooter";
import routes from "../../routes_new";
import footerRoutes from "../../footer.routes";
import bgImage from "../../assets/images/contact_us.jpg";

function ContactUs() {
  const [formData, setFormData] = useState({
    // fullName: '',
    // email: '',
    // message: '',
    user_name: '',
    user_email: '',
    message: '',
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
        'service_r5eh5vi', // Replace with your EmailJS service ID
        'template_lyfe56d', // Replace with your EmailJS template ID
        e.target,
        'YYAX-ES4ORVjdnnqZ' // Replace with your EmailJS user ID
    ).then((result) => {
        console.log(result.text);
        alert('Email sent successfully!');
      }, (error) => {
        console.log(error.text);
        alert('Failed to send email. Please try again later.');
      });

    setFormData({
      // fullName: '',
      // email: '',
      // message: '',
      user_name: '',
      user_email: '',
      message: '',
    });
  };

  return (
    <>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="App">
          <MKBox position="fixed" top="0.5rem" width="100%">
            <DefaultNavbar routes={routes} />
          </MKBox>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MKBox
                display={{ xs: "none", lg: "flex" }}
                width="calc(100% - 2rem)"
                height="calc(100vh - 2rem)"
                borderRadius="lg"
                ml={2}
                mt={2}
                sx={{ backgroundImage: `url(${bgImage})`,backgroundSize: "cover",
                backgroundPosition: "center" }}
              />
            </Grid>
            <Grid
              item
              xs={12}
              sm={10}
              md={7}
              lg={6}
              xl={4}
              ml={{ xs: "auto", lg: 6 }}
              mr={{ xs: "auto", lg: 6 }}
            >
              <MKBox
                bgColor="white"
                borderRadius="xl"
                shadow="lg"
                display="flex"
                flexDirection="column"
                justifyContent="center"
                mt={{ xs: 20, sm: 18, md: 20 }}
                mb={{ xs: 20, sm: 18, md: 20 }}
                mx={3}
              >
                <MKBox
                  variant="contained"
                  bgColor="grey-600"
                  coloredShadow="info"
                  borderRadius="lg"
                  p={2}
                  mx={2}
                  mt={-3}
                >
                  <MKTypography variant="h3" color="white">
                    Contact us
                  </MKTypography>
                </MKBox>
                <MKBox p={3}>
                  <MKTypography variant="body2" color="text" mb={3}>
                    For further questions, including partnership opportunities, please email
                    contact@singularity-box.com or contact using our contact form.
                  </MKTypography>
                  <MKBox width="100%" component="form" onSubmit={handleSubmit} autoComplete="off">
                    <Grid container spacing={3}>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          variant="standard"
                          label="Full Name"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="user_name"
                          value={formData.fullName}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <MKInput
                          type="email"
                          variant="standard"
                          label="Email"
                          InputLabelProps={{ shrink: true }}
                          fullWidth
                          name="user_email"
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <MKInput
                          variant="standard"
                          label="What can we help you?"
                          placeholder="Describe your problem in at least 250 characters"
                          InputLabelProps={{ shrink: true }}
                          multiline
                          fullWidth
                          rows={6}
                          name="message"
                          value={formData.message}
                          onChange={handleChange}
                        />
                      </Grid>
                    </Grid>
                    <Grid container item justifyContent="center" xs={12} mt={5} mb={2}>
                      <MKButton type="submit" variant="content" color="grey-600">
                        Send Message
                      </MKButton>
                    </Grid>
                  </MKBox>
                </MKBox>
              </MKBox>
            </Grid>
          </Grid>
          <MKBox pt={6} px={1} mt={6}>
            <DefaultFooter content={footerRoutes} />
          </MKBox>
        </div>
      </ThemeProvider>
    </>
  );
}

export default ContactUs;
