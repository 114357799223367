/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
import MKBox from "../../../components/MKBox";

// Material Kit 2 React examples
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
// import CenteredBlogCard from "examples/Cards/BlogCards/CenteredBlogCard";

import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";
import CenteredBlogCard from "../../../examples/Cards/BlogCards/CenteredBlogCard";

import CompanyInfo from "../../Company/sections/Singularity.js";

function Information() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <CompanyInfo/>
        <Grid container spacing={3} alignItems="center">
          {/* <Grid item xs={12} lg={6}> */}
            <Grid container justifyContent="flex-start">
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="public"
                    title="Efficient"
                    description="With frequent communication and quality control, our iterative approach offers cost-effective solutions."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={5}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Secure"
                    description="We employ industry standard protocols at every stage of production to combat vulnerabilities and ensure privacy."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="apps"
                    title="Scalable"
                    description="We make modular applications that cater to all use-cases."
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} md={6}>
                <MKBox mb={{ xs: 5, md: 0 }}>
                  <DefaultInfoCard
                    icon="3p"
                    title="Flexible"
                    description="We make the applications flexible."
                  />
                </MKBox>
              </Grid>
            </Grid>
          {/* </Grid> */}
          {/* <Grid item xs={12} lg={4} sx={{ ml: "auto", mt: { xs: 3, lg: 0 } }}>
            <CenteredBlogCard
              image="https://scientiamag.org/wp-content/uploads/2023/06/img.jpg"
              title="Singularity Box Target"
              description="Develop services in any field of Technology."
              action={{
                type: "internal",
                route: "/company",
                to: "/company",
                color: "info",
                label: "find out more",
              }}
            />
          </Grid> */}
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
