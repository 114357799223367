/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";

import Stack from "@mui/material/Stack";
// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";
import MKAvatar from "../../../../components/MKAvatar";
import MKButton from "../../../../components/MKButton";
import MKTypography from "../../../../components/MKTypography";

// Images
import profilePicture from "../../../../assets/images/singularity2.jpg";
import scraperPicture from "../../../../assets/images/scraper.png";
import scraperPicture2 from "../../../../assets/images/scraper2.png";

function Profile() {
  return (
    <MKBox component="section" py={{ xs: 3, md: 12 }}>
      <Container>
        <Grid container alignItems="center">
          <Grid item xs={12} lg={5}>
            {/* Images */}

            <Grid container justifyContent="center" alignItems="center">
            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="700px" // set the desired width
              height="400px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${scraperPicture2})`,    
              backgroundSize: "cover", // or "cover" depending on your requirement
              backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />


            <MKBox
              // display={{ xs: "none", lg: "flex" }}
              // width="calc(100% - 2rem)"
              // height="calc(100vh - 2rem)"
              width="400px" // set the desired width
              height="150px" // set the desired height
              borderRadius="lg"
              // ml={2}
              // mt={2}
              sx={{ backgroundImage: `url(${scraperPicture})`,    
              // backgroundSize: "cover", // or "cover" depending on your requirement
              // backgroundPosition: "center", // you can also use other values like "top", "bottom", "left", "right"
              backgroundRepeat: "no-repeat" 
              }}
            />
            </Grid>


            <MKTypography variant="h3" my={1} mt={6}>
              WEB SCRAPER
            </MKTypography>
            <MKTypography variant="body2" color="text" mb={2}>
              Our web scraper is a sophisticated tool designed to extract data efficiently from various websites. It operates by sending requests to web pages and parsing HTML or other content to retrieve specific information. This solution is essential for businesses needing to automate data collection from multiple sources. 
            </MKTypography>

          </Grid>
          <Grid item xs={12} lg={6} sx={{ ml: { xs: -2, lg: "auto" }, mt: { xs: 6, lg: 0 } }}>
            <Stack>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">list_alt</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">CHALLENGES</MKTypography>
                  Developing an effective web scraper comes with its own set of challenges:
                  <li>Security Policies: Each website has unique security measures, making it <br/>challenging to extract specific information.</li>
                  <li>Infrastructure Variability: The varying structures of websites necessitate a <br/>versatile scraper capable of handling diverse formats.</li>
                </MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"z
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">task_alt</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKTypography variant="h6">SOLUTIONS</MKTypography>
                  We engineered a robust web scraper with advanced conditional logic, <br/>allowing it to adapt and function seamlessly across different e-commerce platforms. <br/>This tailored approach ensures reliable data extraction regardless of the <br/>website’s infrastructure.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">assignment_ind</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                  <MKTypography variant="h6">CLIENT</MKTypography>
                  Lux Second Chance
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">warehouse</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">INDUSTRY</MKTypography>
                  E-commerce.
                </MKTypography>
              </MKBox>

              <MKBox display="flex" alignItems="center" p={2}>
                <MKBox
                  width="3rem"
                  height="3rem"
                  // variant="gradient"
                  // bgColor="info"
                  variant="contained"
                  bgColor="grey-600"
                  color="white"
                  coloredShadow="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                  borderRadius="xl"
                >
                  <Icon fontSize="small">terminal</Icon>
                </MKBox>
                <MKTypography variant="body2" color="text" pl={2}>
                <MKTypography variant="h6">TECHNOLOGIES</MKTypography>

                  <li>Python</li>
                  <li>PHP</li>
                  <li>Selenium</li>
                  <li>Google Chrome</li>
                  <li>Mysql</li>
                  <li>Linode Storage Object</li>

                </MKTypography>
              </MKBox>

            </Stack>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Profile;
