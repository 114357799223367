import React from 'react';
// import Header from './components/Header';
import { ThemeProvider } from '@mui/material/styles';
import theme from './assets/theme';
import CssBaseline from "@mui/material/CssBaseline";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// import Hero from './components/Hero';
// import Clients from './components/Clients';
import Footer from './components/Footer';


import HeaderOne from './components/HeaderOne';
import Applications from "./pages/Presentation/sections/Applications";
import Clients from "./pages/Presentation/sections/Clients";
import Information from "./pages/Presentation/sections/Information";


import Counters from "./pages/Presentation/sections/Counters";
import MKSocialButton from "./components/MKSocialButton";
import MKBox from "./components/MKBox";
import MKTypography from "./components/MKTypography";

import routes from "./routes_new";
import DefaultNavbar from "./examples/Navbars/DefaultNavbar";

import DefaultFooter from "./examples/Footers/DefaultFooter";
import footerRoutes from "./footer.routes";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <div className="App">
        <DefaultNavbar
          routes={routes}
          // action={{
          // type: "external",
          // route: "https://www.creative-tim.com/product/material-kit-react",
          // label: "free download",
          // color: "default",
          // }}
          // transparent
          // light
        />
        <HeaderOne />
        {/* <Counters />
        

        <Information /> */}

        {/* <Header /> */}
        {/* <Hero /> */}
        {/* <Clients /> */}

        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Counters />
          <Information />
          <Clients />
          <Applications />
        
                

          {/* The almost last part */}
          {/* <MKBox pt={18} pb={6}>
            <Container>
              <Grid container spacing={3}>
                <Grid item xs={12} lg={5} ml="auto" sx={{ textAlign: { xs: "center", lg: "left" } }}>
                  <MKTypography variant="h4" fontWeight="bold" mb={0.5}>
                    Welcome to Singularity Box LLC!
                  </MKTypography>
                  <MKTypography variant="body1" color="text">
                    We deliver the best web products
                  </MKTypography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  lg={5}
                  my={{ xs: 5, lg: "auto" }}
                  mr={{ xs: 0, lg: "auto" }}
                  sx={{ textAlign: { xs: "center", lg: "right" } }}
                >
                  <MKSocialButton
                    component="a"
                    href="https://twitter.com/intent/tweet?text=Check%20Material%20Design%20System%20made%20by%20%40CreativeTim%20%23webdesign%20%23designsystem%20%23mui5&amp;url=https%3A%2F%2Fwww.creative-tim.com%2Fproduct%2Fmaterial-kit-react"
                    target="_blank"
                    color="twitter"
                    sx={{ mr: 1 }}
                  >
                    <i className="fab fa-twitter" />
                    &nbsp;Tweet
                  </MKSocialButton>
                  <MKSocialButton
                    component="a"
                    href="https://www.facebook.com/sharer/sharer.php?u=https://www.creative-tim.com/product/material-kit-react"
                    target="_blank"
                    color="facebook"
                    sx={{ mr: 1 }}
                  >
                    <i className="fab fa-facebook" />
                    &nbsp;Share
                  </MKSocialButton>
                  <MKSocialButton
                    component="a"
                    href="https://www.pinterest.com/pin/create/button/?url=https://www.creative-tim.com/product/material-kit-react"
                    target="_blank"
                    color="pinterest"
                  >
                    <i className="fab fa-pinterest" />
                    &nbsp;Pin it
                  </MKSocialButton>
                </Grid>
              </Grid>
            </Container>
          </MKBox> */}
        </Card>


        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>


        {/* <Footer /> */}
      </div>
    </ThemeProvider>
  );
}

export default App;
