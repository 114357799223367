/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
// import MKBox from "components/MKBox";
import MKBox from "../../../components/MKBox";
import colors from "../../../assets/theme/base/colors";

// Material Kit 2 React examples
// import RotatingCard from "examples/Cards/RotatingCard";
// import RotatingCardFront from "examples/Cards/RotatingCard/RotatingCardFront";
// import RotatingCardBack from "examples/Cards/RotatingCard/RotatingCardBack";
// import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";
import RotatingCard from "../../../examples/Cards/RotatingCard";
import RotatingCardFront from "../../../examples/Cards/RotatingCard/RotatingCardFront";
import RotatingCardBack from "../../../examples/Cards/RotatingCard/RotatingCardBack";
import DefaultInfoCard from "../../../examples/Cards/InfoCards/DefaultInfoCard";

// Images
// import bgFront from "assets/images/rotating-card-bg-front.jpeg";
// import bgBack from "assets/images/rotating-card-bg-back.jpeg";
import bgFront from "../../../assets/images/tech1.jpg";
import bgBack from "../../../assets/images/tech2.jpg";

import Icon from "@mui/material/Icon";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FlipToFrontIcon from '@mui/icons-material/FlipToFront';
import PriceChangeIcon from '@mui/icons-material/PriceChange';
import DevicesIcon from '@mui/icons-material/Devices';
import TouchAppIcon from '@mui/icons-material/TouchApp';
const { grey } = colors;

function Information() {
  return (
    <MKBox component="section" py={6} my={6}>
      <Container>
        <Grid container item xs={11} spacing={3} alignItems="center" sx={{ mx: "auto" }}>
          <Grid item xs={12} lg={4} sx={{ mx: "auto" }}>
            <RotatingCard>
              <RotatingCardFront
                image={bgFront}
                // icon="touch_app"
                icon={<TouchAppIcon fontSize="large"/>}
                // icon={<Icon>touch_app</Icon>}
                title={
                  <>
                    Our Services
                    <br />
                    Quality service providers
                  </>
                }
                description="Provide an appropriate solution oriented to the client's needs that allows obtaining effective solutions based on standards and best practices."
                // description="All the MUI components that you need in a development have been re-design with the new look."

              />
              <RotatingCardBack
                image={bgBack}
                title="Discover More"
                description="You will save a lot of time going from prototyping to full-functional code because all elements are implemented."
                action={{
                  type: "internal",
                  to: "/contact-us", 
                  route: "/contact-us",
                  label: "start your next project with us",
                }}
              />
            </RotatingCard>
          </Grid>
          <Grid item xs={12} lg={7} sx={{ ml: "auto" }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="content_copy"
                  // icon={<ContentCopyIcon fontSize="large" color="info"/>}
                  title="Expertise"
                  description="Our team comprises highly skilled engineers with certifications in Cisco CNNA, Fortigate, Amazon Web Services, and Azure. We also have certified DevOps engineers, administrators, and developers."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="flip_to_front"
                  // icon={<FlipToFrontIcon fontSize="large" color="info"/>}
                  title="Innovative Solutions"
                  description="We leverage the latest technologies in networking, programming, and cloud management to provide cutting-edge solutions."
                />
              </Grid>
            </Grid>
            <Grid container spacing={3} sx={{ mt: { xs: 0, md: 6 } }}>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="price_change"
                  // icon={<PriceChangeIcon fontSize="large" color="info"/>}
                  title="Comprehensive Services"
                  description="From cybersecurity to cloud solutions, we offer a wide range of services tailored to your business needs."
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <DefaultInfoCard
                  icon="devices"
                  // icon={<DevicesIcon fontSize="large" color="info"/>}
                  title="Reliability"
                  description="With our extensive experience and robust infrastructure, we guarantee reliable and efficient service delivery."
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Information;
