import { ThemeProvider } from '@mui/material/styles';
import theme from '../../../../assets/theme';
import CssBaseline from "@mui/material/CssBaseline";

// @mui material components
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "../../../../components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "../../../../examples/Navbars/DefaultNavbar";

// Author page sections
import Profile from "../../../Company/sections/Profile";
import Posts from "../../../Company/sections/Posts";
import Contact from "../../../Company/sections/Contact";
import Footer from "../../../Company/sections/Footer";
import Project from "./Info5";

// Routes
import routes from "../../../../routes_new";

// Images
// import bgImage from "../../assets/images/city-profile.jpg";
import bgImage from "../../../../assets/images/tech1.jpg";


import DefaultFooter from "../../../../examples/Footers/DefaultFooter";
import footerRoutes from "../../../../footer.routes";

function Author() {
  return (
    <>
    <ThemeProvider theme={theme}>
    <CssBaseline />
      <DefaultNavbar
        routes={routes}
        // action={{
        // type: "external",
        // route: "https://www.creative-tim.com/product/material-kit-react",
        // label: "free download",
        // color: "default",
        // }}
        // transparent
        // light
      />
      <MKBox bgColor="white">
        <MKBox
          minHeight="25rem"
          width="100%"
          sx={{
            backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
              `${linearGradient(
                rgba(gradients.dark.main, 0.8),
                rgba(gradients.dark.state, 0.8)
              )}, url(${bgImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            display: "grid",
            placeItems: "center",
          }}
        />
        <Card
          sx={{
            p: 2,
            mx: { xs: 2, lg: 3 },
            mt: -8,
            mb: 4,
            backgroundColor: ({ palette: { white }, functions: { rgba } }) => rgba(white.main, 0.8),
            backdropFilter: "saturate(200%) blur(30px)",
            boxShadow: ({ boxShadows: { xxl } }) => xxl,
          }}
        >
          <Project />
          {/* <Profile /> */}
          {/* <Posts /> */}
        </Card>
        {/* <Contact /> */}
        {/* <Footer /> */}
        <MKBox pt={6} px={1} mt={6}>
          <DefaultFooter content={footerRoutes} />
        </MKBox>
      </MKBox>
    </ThemeProvider>
    </>
  );
}

export default Author;
